@media print {
  body * {
    visibility: hidden;
  }

  .printable-container,
  .printable-container * {
    visibility: visible;
  }

  .printable-container {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0in;
    /* Optional: add padding for additional space */
    page-break-after: auto;
    /* Ensures that pages break appropriately */
    page-break-before: auto;
    page-break-inside: avoid;
    /* Prevents content from being split inside elements */
    width: 100%; 
  }


  /* Ensure consistent margins on every printed page */
  @page {
    margin: 0.25in; /* Margins on the printed page */
  }

  .mix-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(60px, auto));
    gap: 0 px; /* Space between grid items */
    margin-top: 0px; /* Adds some space above the grid */
  }
  
  .mix-item {
    border: 1px solid black; /* Border around each mix item */
    padding: 0px; /* Padding inside each item */
    text-align: center; /* Center-aligns the text */
    white-space: nowrap; /* Keeps mixMax and unit abbreviation on the same line */
  }
  
  

  .page-break {
    page-break-before: always; /* This will ensure the element starts on a new page */
    break-before: always; /* For modern browsers */
  }
  
}