.login-container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;

    h2 {
      margin-bottom: 20px;
    }

    input {
      width: 300px;
      height: 40px;
      margin: 10px;
      padding: 0 10px;
      border: 1px solid #ccc;
      border-radius: 5px;
      font-size: 16px;
      outline: none;
    }

    button {
      width: 320px;
      height: 40px;
      margin-top: 20px;
      border: none;
      border-radius: 5px;
      background-color: purple;
      color: white;
      font-size: 16px;
      font-weight: bold;
      cursor: pointer;
      transition: background-color 0.3s;
    }

    button:hover {
      background-color: #6a0dad;
    }

    .error {
      font-size: 14px;
      color: red;
      margin-top: 10px;
    }
  }
}
